<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/banner/list' }">首页幻灯</el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form"  :rules="rules" label-width="150px" class="el-form-self">

        <el-row>
          <el-col>
            <el-form-item label="类型:" prop="type">
              <el-select v-model="form.type" placeholder="请选择" class="el-select-big" clearable>
                <el-option
                        v-for="item in typeArray"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col>
            <el-form-item label="名称:" prop="bannerName">
              <el-input v-model="form.bannerName" auto-complete="off" maxlength="50" show-word-limit placeholder="请输入名称" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>



        <el-row>
          <el-col>
            <el-form-item label="序号:" prop="numberOrder">
              <el-input v-model="form.numberOrder" auto-complete="off" placeholder="请输入序号" maxlength="5" show-word-limit clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="幻灯图片:" prop="imageUrl" class="is-required">
              <el-upload
                class="homeBannerImage-uploader"
                :action="uploadFileURL"
                :show-file-list="false"
                :on-success="handleBannerImageSuccess"
                :before-upload="beforeBannerImageUpload">
                <img v-if="imageUrl" :src="imageUrl" class="homeBannerImage">
                <i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">注意：点击 + 上传.jpg, .png后缀图片文件。图片尺寸为720px x 375px。</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-buttons">
          <el-col>
            <el-form-item class="el-form-item-self">
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button type="primary" @click="$router.push({path:'/banner/list',query:{filtersName:$route.query.filtersName}})">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>


    </el-col>
  </el-row>

</template>
<script>
  import {reqAddBanner} from '../../../api/banner-api'
  import {uploadFileURL,downloadFileURL} from '../../../api/system-api'

  export default {

    data() {
      return {
        uploadFileURL:uploadFileURL,
        imageUrl: '',
        typeArray:[
          {name:'首页幻灯',value:'1'},
          {name:'服务幻灯',value:'2'},
        ],
        form: {
          bannerName: '',
          columns:'',
          content: '',
          numberOrder: '',
          isUse:'',
          type: '',
          imageUrl: '',

        },
          rules: {
              type:[
                  {required: true, message: '请选择类型'},
              ],
              numberOrder: [
                  {pattern: /^\d{1,5}$/, message:'排序只能输入整数',trigger: 'blur'},
              ],
          },
      }
    },
    methods: {

      onSubmit() {
        var that = this;
          this.$refs.form.validate((valid) => {
              if (valid) {
                  if(!that.form.imageUrl){
                      that.$message({
                          message: '请输入图片',
                          type: 'warning'
                      });
                    return false;
                  }
                  this.form.isUse=2;
                  let para = Object.assign({}, this.form);
                  reqAddBanner(para).then((res) => {
                      if(res.data.code===1){
                          that.$message({
                              message: '提交成功',
                              type: 'success'
                          });
                          that.$router.push({path:"/banner/list"});
                      }else {
                          that.$message({
                              message: '提交失败',
                              type: 'error'
                          });
                          that.$router.push({path:"/banner/list"});
                      }
                  })
              }
          });

      },

      beforeBannerImageUpload(file) {
        const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
        const isLt5M = file.size / 1024 / 1024 < 5;

        if (!isValidType) {
          this.$message.error('不支持改类型的文件上传。');
        }
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isValidType && isLt5M;
      },
      handleBannerImageSuccess(response) {
        if (response.code === 1) {
          this.form.imageUrl = response.data;
          this.imageUrl=downloadFileURL + response.data + "/";
          console.log(this.imageUrl)
        }
      },

    },
    mounted() {
    }
  };
</script>
